<template>
  <div>
    <NavBarFreeMain />
    <div class="card-header">
      Contáctanos
    </div>
    <div class="card-body">
      <div class="card-body__confirmation" v-if="isSuccess">
        <div class="card-body__confirmation__success-image">
          <img src="icons/icn-msg-success.svg" alt="Success" />
        </div>
        <p class="card-body__confirmation__title">
          Tu mensaje ha sido enviado
        </p>
        <p class="card-body__confirmation__info">
          Nuestro equipo de atención se pondrá en contacto contigo a la brevedad.
        </p>
        <button id="btn-continue-nav" class="btn-contact" @click="$router.push({ name: 'main' })">
          Continuar navegando
        </button>
      </div>
      <div v-else>
        <p>
          Escríbenos cualquier comentario, duda o sugerencia y nuestro equipo de atención se pondrá en contacto contigo
          a la brevedad para ayudarte.
        </p>
        <ValidationObserver ref="observer" class="card-body__contact-form" v-slot="{ handleSubmit, invalid }">
          <BInputWithValidation v-model="name" label="Nombre *" rules="required" placeholder="Nombre completo" />
          <BInputWithValidation
            v-model="email"
            label="E-mail *"
            type="email"
            placeholder="ejemplo@micorreo.com"
            :disabled="isEmailDisabled"
            rules="required|email"
          />
          <BSelectWithValidation
            v-model="reason"
            label="Motivo *"
            rules="required"
            placeholder="Selecciona uno"
            expanded
          >
            <option v-for="(option, index) in options" :value="option" :key="index">
              {{ option }}
            </option>
          </BSelectWithValidation>
          <div id="form-comment">
            <BInputWithValidation
              v-model="comment"
              label="Comentario *"
              type="textarea"
              placeholder="Escribe tu duda, comentario o sugerencia."
              rules="required"
            />
          </div>
          <button id="form-send-button" class="btn-contact" @click="onSendClicked" :disabled="invalid">
            Enviar
          </button>
        </ValidationObserver>
      </div>
      <VueRecaptcha
        ref="recaptcha"
        @verify="onCaptchaVerified"
        @expired="resetCaptcha"
        size="invisible"
        :sitekey="captchaKey"
      />
    </div>
    <Footer />
    <script
      type="application/javascript"
      src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
      defer
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import NavBarFreeMain from '@/components/Sections/Main/NavBarFreeMain';
import Footer from '@/components/Sections/Main/Footer';
import BInputWithValidation from '@/components/Form/BInputWithValidation';
import BSelectWithValidation from '@/components/Form/BSelectWithValidation';
import { sendComment } from '@/utils/api/profile';
import VueRecaptcha from 'vue-recaptcha';
import captcha from '@/data/captcha';
import { clickEnviarMensajeDeContacto } from '@/utils/analytics';
export default {
  name: 'Contact',
  components: { BSelectWithValidation, ValidationObserver, BInputWithValidation, VueRecaptcha, NavBarFreeMain, Footer },
  props: {
    showLogin: Boolean,
  },
  data: function() {
    return {
      name: '',
      email: '',
      reason: '',
      comment: '',
      isSuccess: false,
      isEmailDisabled: false,
      options: ['Cuentas Premium', 'Costo', 'Pagos/Cobro', 'Usuario/Contraseña', 'Sugerencias', 'Otros'],
      captchaKey: captcha.key,
    };
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  watch: {
    user: {
      deep: true,
      handler(newValue) {
        if (newValue.premiumAccount) {
          this.fillUserInfo();
        } else {
          this.resetData();
        }
      },
    },
  },
  methods: {
    ...mapMutations(['setIsSectionLoading']),
    ...mapActions('profileGeneral', ['getUserInfo']),
    ...mapActions('alertGeneral', ['setAlert']),
    async onSendClicked() {
      clickEnviarMensajeDeContacto(this.getPremiumAccount, this.getIsGolstats);
      await this.$refs.recaptcha.execute();
    },
    async fillUserInfo() {
      try {
        if (this.user && this.user.premiumAccount) {
          this.setIsSectionLoading(true);
          const { account_name, mail } = await this.getUserInfo();
          this.name = account_name;
          this.email = mail;
          this.isEmailDisabled = true;
        }
      } catch (e) {
        this.setAlert({
          type: 'error',
          message: e.response.data.message,
        });
      } finally {
        this.setIsSectionLoading(false);
      }
    },
    resetData() {
      this.name = '';
      this.email = '';
      this.reason = null;
      this.comment = '';
      this.isEmailDisabled = false;
      this.$refs.observer.reset();
    },
    onCaptchaVerified(token) {
      this.resetCaptcha();
      this.next(token);
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
    async next(token) {
      try {
        this.setIsSectionLoading(true);
        await sendComment({
          nombre: this.name,
          email: this.email,
          motivo: this.reason,
          descripcion: this.comment,
          recaptcha: token,
        });
        this.isSuccess = true;
        this.resetData();
      } catch (e) {
        this.setAlert({
          type: 'error',
          message: e.response.data.mensaje,
        });
      } finally {
        this.setIsSectionLoading(false);
      }
    },
  },
  mounted() {
    this.resetData();
    this.fillUserInfo();
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
</style>
<style scoped lang="scss">
.card-header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.65rem;
  font-family: Circular-Std, sans-serif;
  padding: 18px 0 20px;
  background-color: #fcfcfc;
}

.card-body {
  max-width: 1000px;
  text-align: center;
  padding: 40px 20px;
  margin: 0 auto;

  & p {
    text-align: left;
    font-family: Avenir-Regular, sans-serif;
  }

  &__contact-form {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 18px;
    row-gap: 14px;
    margin: 36px 0;

    & #form-comment {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    & #form-send-button {
      grid-column-start: 3;
      grid-column-end: 4;
      justify-self: end;
    }
  }

  &__confirmation {
    & p {
      text-align: center;

      &.card-body__confirmation__title {
        font-family: Avenir-Pro-Bold, sans-serif;
        font-size: 24px;
      }

      &.card-body__confirmation__info {
        max-width: 400px;
        margin: 0 auto;
      }
    }

    &__success-image {
      margin: 50px 0;
    }
  }
}

.btn-contact {
  width: fit-content;
  padding: 12px 54px;
  border: 0;
  border-radius: 6px;
  font-size: 20px;
  color: white;
  background-color: #428ee6;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: #e5e5e5;
    cursor: default;
  }
}

#btn-continue-nav {
  margin: 54px 0;
}

@media screen and (max-width: 589px) {
  .card-body {
    &__contact-form {
      display: grid;
      grid-template-columns: 1fr;

      & #form-comment {
        grid-column-start: 1;
        grid-column-end: 2;
      }

      & #form-send-button {
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }

    &__confirmation {
      &__success-image {
        margin: 10px 0;
      }
    }
  }

  .btn-contact {
    width: 100%;
    padding: 12px;
    font-size: 18px;
    color: white;
  }

  #btn-continue-nav {
    width: 100%;
    font-size: 16px;
    margin: 20px 0;
  }
}
</style>
